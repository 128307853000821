import EmailSubscriptionService from './EmailSubscriptionService';
import fetch from '../api/fetch';
import { document } from '../globals';
import ErrorLogger from '../error-logger/ErrorLogger';

const emailSubscriptionService = new EmailSubscriptionService(
    fetch,
    (error: Error) => ErrorLogger.createFromGlobals()?.log(error),
);

if (document) {
    emailSubscriptionService.bindScope(document.body);
}
