import { document } from '../globals';

type SubscribeAction = 'subscribe' | 'unsubscribe';
type Subscription = 'b2c' | 'b2b';

interface SubscriptionResponse {
    message: string;
    success: boolean;
}

export default class EmailSubscriptionService {
    private static readonly CONTAINER_SELECTOR = '.js-email-subscription';

    private static readonly FORM_SELECTOR = '.js-email-subscription-form';

    private static readonly EMAIL_ADDRESS_FIELD_SELECTOR = '.js-email-address';

    private static readonly RESPONSE_CONTAINER_SELECTOR = '.js-email-subscription-response';

    private static readonly DATASET_SUBSCRIPTION_ACTION = 'subscriptionAction';

    private static readonly DATASET_SUBSCRIPTION_TYPE = 'subscriptionType';

    private static readonly DATASET_SUBSCRIPTION_METHOD = 'subscriptionMethod';

    private static readonly B2C_SUBSCRIBE_ENDPOINT = 'email/subscribe-to-b2c-newsletter';

    private static readonly B2B_SUBSCRIBE_ENDPOINT = 'email/subscribe-to-b2b-newsletter';

    private static readonly UNSUBSCRIBE_ENDPOINT = 'email/unsubscribe';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly logError: (error: any) => void;

    private readonly fetch: Window['fetch'];

    public constructor(
        fetch: Window['fetch'],
        errorLogger: (error: Error) => void,
    ) {
        this.fetch = fetch;
        this.logError = errorLogger;
    }

    public bindScope(scope: HTMLElement): void {
        const containers = scope.querySelectorAll<HTMLElement>(EmailSubscriptionService.CONTAINER_SELECTOR);

        containers.forEach((container: HTMLElement) => {
            try {
                const form = container.querySelector<HTMLFormElement>(EmailSubscriptionService.FORM_SELECTOR);
                if (form) {
                    this.bindSubmitHandler(container);
                }
            } catch (e) {
                this.logError(e);
            }
        });
    }

    private bindSubmitHandler(container: HTMLElement): void {
        const form = container.querySelector<HTMLFormElement>(EmailSubscriptionService.FORM_SELECTOR);
        if (!form) {
            throw new Error('Unable to find subscription form');
        }

        const emailAddressField = form.querySelector<HTMLInputElement>(EmailSubscriptionService.EMAIL_ADDRESS_FIELD_SELECTOR);
        if (!emailAddressField) {
            throw new Error('Unable to find email address field for subscription form');
        }

        const method = form.dataset[EmailSubscriptionService.DATASET_SUBSCRIPTION_METHOD];
        const action: SubscribeAction = EmailSubscriptionService.getSubscriptionAction(form);

        let submitHandler;
        if (action === 'unsubscribe') {
            submitHandler = this.getUnsubscribeHandler(container, emailAddressField, method);
        } else {
            const subscription: Subscription = EmailSubscriptionService.getSubscription(form);

            submitHandler = this.getSubscribeHandler(container, subscription, emailAddressField, method);
        }

        form.addEventListener('submit', submitHandler);
    }

    private static getSubscriptionAction(form: HTMLElement): SubscribeAction {
        const subscriptionAction = form.dataset[EmailSubscriptionService.DATASET_SUBSCRIPTION_ACTION];

        if (!subscriptionAction) {
            throw new Error('Missing subscription action');
        }

        if (subscriptionAction === 'subscribe' || subscriptionAction === 'unsubscribe') {
            return subscriptionAction;
        }

        throw new Error(`Invalid subscription action '${subscriptionAction}'`);
    }

    private static getSubscription(form: HTMLElement): Subscription {
        const subscriptionType = form.dataset[EmailSubscriptionService.DATASET_SUBSCRIPTION_TYPE];

        if (!subscriptionType) {
            throw new Error('Missing subscription type');
        }

        if (subscriptionType === 'b2c' || subscriptionType === 'b2b') {
            return subscriptionType;
        }

        throw new Error(`Invalid subscription type '${subscriptionType}'`);
    }

    private getUnsubscribeHandler(
        container: HTMLElement,
        emailAddressField: HTMLInputElement,
        method?: string,
    ) {
        return (event: Event): void => {
            event.preventDefault();

            this.sendSubscriptionRequest(
                EmailSubscriptionService.UNSUBSCRIBE_ENDPOINT,
                emailAddressField.value,
                method,
            ).then((response: SubscriptionResponse) => {
                EmailSubscriptionService.showResponse(container, response);
            }).catch((error: Error) => { throw error; });
        };
    }

    private getSubscribeHandler(
        container: HTMLElement,
        subscription: Subscription,
        emailAddressField: HTMLInputElement,
        method?: string,
    ) {
        return (event: Event): void => {
            event.preventDefault();

            this.sendSubscriptionRequest(
                subscription === 'b2b' ? EmailSubscriptionService.B2B_SUBSCRIBE_ENDPOINT : EmailSubscriptionService.B2C_SUBSCRIBE_ENDPOINT,
                emailAddressField.value,
                method,
            ).then((response: SubscriptionResponse) => {
                EmailSubscriptionService.showResponse(container, response);
            }).catch((error: Error) => { throw error; });
        };
    }

    private async sendSubscriptionRequest(endpoint: string, email: string, method?: string): Promise<SubscriptionResponse> {
        const data = { email, location: document?.location.href, method };

        return this.fetch(endpoint, {
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
        })
            .then((response) => response.json() as Promise<SubscriptionResponse>);
    }

    private static showResponse(container: HTMLElement, response: SubscriptionResponse): void {
        const form = container.querySelector<HTMLElement>(EmailSubscriptionService.FORM_SELECTOR);
        const responseContainer = container.querySelector<HTMLElement>(EmailSubscriptionService.RESPONSE_CONTAINER_SELECTOR);

        if (form && response.success) {
            form.classList.add('hidden');
        }

        if (responseContainer) {
            if (response.success) {
                responseContainer.classList.remove('textColor--error');
                responseContainer.classList.add('textColor--success');
            } else {
                responseContainer.classList.remove('textColor--success');
                responseContainer.classList.add('textColor--error');
            }

            if (!response.message) {
                throw new Error('Subscription response included no message');
            }

            responseContainer.innerText = response.message;
            responseContainer.classList.remove('hidden');
        }
    }
}
